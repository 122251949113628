<template>
  <mobile-screen :header="true" screen-class="icon-app1 cockpit-screen gray-bg">
    <template v-slot:header>
      <top-header-menu-wrapper menu-class="cockpit-header icon-hea1">
        <template v-slot:left>
          <router-link :to="{ name: 'r_cockpit' }">
            <icon icon="#cx-hea1-arrow-left" />
          </router-link>
        </template>
        <div class="component-title">
          {{ displayLabelName("cockpit", "settings", "settings") }}
        </div>
      </top-header-menu-wrapper>
    </template>
    <ul
      class="clebex-item-section pill"
      v-if="widgetTypes && widgetTypes.length"
    >
      <li
        class="clebex-item-section-item slide-icon-border full-right-underline"
        v-for="widget in widgetTypes"
        :key="widget.id"
      >
        <div class="clebex-item-content-wrapper">
          <dl class="clebex-item-dl justify-start">
            <dt class="clebex-item-dt">
              <div class="checkbox slide">
                <input
                  :id="`cockpitSettings${widget.id}`"
                  type="checkbox"
                  v-model="widget.is_active"
                  @change="setUserWidget(widget.id)"
                />
                <label :for="`cockpitSettings${widget.id}`"></label>
              </div>
            </dt>
            <dd class="clebex-item-dd justify-start">
              <button @click="widgetSettingsPage(widget.type)" class="w-100">
                <div>
                  {{ widget.name }}
                </div>
                <icon
                  v-if="widget.is_active"
                  icon="#cx-app1-arrow-right-12x12"
                  width="12"
                  height="12"
                />
              </button>
            </dd>
          </dl>
        </div>
      </li>
    </ul>
    <template v-slot:footer>
      <nav class="actions-menu theme-gray"></nav>
    </template>
  </mobile-screen>
  <router-view />
</template>

<script>
import MobileScreen from "@/layouts/MobileScreen";
import TopHeaderMenuWrapper from "@/components/global/TopHeaderMenuWrapper";
import { mapState, mapActions } from "vuex";
import { widgets } from "@/services/consts/modules/cockpit/widgets";

export default {
  name: "CockpitSettings",
  components: {
    MobileScreen,
    TopHeaderMenuWrapper
  },
  created() {
    this.getWidgetsTypes();
  },
  computed: {
    ...mapState("cockpit", ["widgetTypes"])
  },
  methods: {
    ...mapActions("cockpit", ["getWidgetsTypes", "setUserWidget"]),
    widgetSettingsPage(type) {
      if (!this.widgetTypes.find(el => el.type === type).is_active) {
        return;
      }
      this.$router.push({
        name: widgets[type].settingsPage
      });
    }
  }
};
</script>
