export const widgets = {
  VISITOR_MANAGEMENT: {
    settingsPage: "r_cockpit-settings-visitor-management",
    componentName: "VisitorManagement"
  },
  BOOKINGS: {
    settingsPage: "r_cockpit-settings-bookings",
    componentName: "Bookings"
  }
};
